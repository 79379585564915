@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
      @apply text-2xl;
    }
    h6 {
      @apply text-xl;
    }
    /* ... */
  }